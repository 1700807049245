import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Section,
  SectionTitle,
  SectionDescription,
  SectionStatic,
  BreadCrumb,
} from "../components/Section"
import { BulletIcon } from "../components/Icons"
import TopPageHeader from "../components/TopPageHeader"
import ContactCard from "../components/ContactCard"
import BreakpointUp from "../components/Media/BreakpointUp"
import { v4 } from "uuid"

const SitemapGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 30px;
  ${BreakpointUp.sm`    
        grid-template-columns: repeat(2, 1fr);     
    `}
`
const SitemapItem = styled.div`
  & h2 {
    & a {
      color: #211f28;
      &:hover {
        color: #6a6a6a;
      }
    }
    font-size: 18px;
    line-height: 24px;
    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 26px;
    }
    @media (min-width: 992px) {
      font-size: 26px;
      line-height: 30px;
    }
  }
  & ul {
    margin-bottom: 30px;
    & li {
      margin-bottom: 10px;
      & a {
        color: #6a6a6a;
        &:hover {
          color: #211f28;
        }
      }
    }
  }
`
const SitemapPage = ({ intl, data }) => {
  const products = data.allContentfulProduct.edges
  const articles = data.allContentfulArticle.edges
  return (
    <Layout>
      <SEO title="Sitemap" />
      <BreadCrumb>
        <div className="container">
          <Link to="/">
            Home <BulletIcon />
          </Link>
          <span>Sitemap</span>
        </div>
      </BreadCrumb>
      <TopPageHeader topHeaderTitle="Sitemap" />
      <Section
        pt="0"
        pb="90px"
        xpt="0"
        xpb="60px"
        bgColor="transparent"
        top="-100px"
        xtop="-50px"
      >
        <div className="container">
          <SectionStatic pl="80px" pr="80px">
            <SitemapGrid>
              <SitemapItem>
                <h2>
                  <Link to={`/${intl.locale}/metal-buildings`}>
                    {intl.formatMessage({ id: "Metal Buildings" })}
                  </Link>
                </h2>
                <ul>
                  <li>
                    <Link to={`/${intl.locale}/commercial-buildings`}>
                      <span>
                        {intl.formatMessage({ id: "Commercial Buildings" })}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${intl.locale}/clear-span-buildings`}>
                      <span>
                        {intl.formatMessage({ id: "Clear Span Buildings" })}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${intl.locale}/custom-buildings`}>
                      <span>
                        {intl.formatMessage({ id: "Custom Buildings" })}
                      </span>
                    </Link>
                  </li>
                </ul>
                <h2>
                  <Link to={`/${intl.locale}/metal-carports`}>
                    {intl.formatMessage({ id: "Metal Carports" })}
                  </Link>
                </h2>
                <ul>
                  <li>
                    <Link to={`/${intl.locale}/vertical-roof-carports`}>
                      <span>
                        {intl.formatMessage({ id: "Vertical Roof Carports" })}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${intl.locale}/a-frame-roof-carports`}>
                      <span>
                        {intl.formatMessage({ id: "A-Frame Roof Carports" })}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${intl.locale}/regular-roof-carports`}>
                      <span>
                        {intl.formatMessage({ id: "Regular Roof Carports" })}
                      </span>
                    </Link>
                  </li>
                </ul>
                <h2>
                  <Link to={`/${intl.locale}/metal-garages`}>
                    {intl.formatMessage({ id: "Metal Garages" })}
                  </Link>
                </h2>
                <ul>
                  <li>
                    <Link to={`/${intl.locale}/vertical-roof-garages`}>
                      <span>
                        {intl.formatMessage({ id: "Vertical Roof Garages" })}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${intl.locale}/a-frame-roof-garages`}>
                      <span>
                        {intl.formatMessage({ id: "A-Frame Roof Garages" })}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${intl.locale}/regular-roof-garages`}>
                      <span>
                        {intl.formatMessage({ id: "Regular Roof Garages" })}
                      </span>
                    </Link>
                  </li>
                </ul>
                <h2>
                  <Link to={`/${intl.locale}/metal-barns`}>Metal Barns</Link>
                </h2>
                <ul>
                  <li>
                    <Link to={`/${intl.locale}/continuous-roof-barns`}>
                      <span>
                        {intl.formatMessage({ id: "Continuous Roof Barns" })}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${intl.locale}/vertical-roof-barns`}>
                      <span>
                        {intl.formatMessage({ id: "Vertical Roof Barns" })}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${intl.locale}/a-frame-roof-barns`}>
                      <span>
                        {intl.formatMessage({ id: "A-Frame Roof Barns" })}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${intl.locale}/regular-roof-barns`}>
                      <span>
                        {intl.formatMessage({ id: "Regular Roof Barns" })}
                      </span>
                    </Link>
                  </li>
                </ul>
                <h2>{intl.formatMessage({ id: "Financing/RTO" })}</h2>
                <ul>
                  <li>
                    <Link to={`/${intl.locale}/rto`}>
                      <span>{intl.formatMessage({ id: "Rent-To-Own" })}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${intl.locale}financing`}>
                      <span>{intl.formatMessage({ id: "Financing" })}</span>
                    </Link>
                  </li>
                </ul>
                <h2>{intl.formatMessage({ id: "Buyer's Guide" })}</h2>
                <ul>
                  <li>
                    <Link to={`/${intl.locale}/purchase-process`}>
                      <span>
                        {intl.formatMessage({ id: "Purchase Process" })}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${intl.locale}/site-preparation`}>
                      <span>
                        {intl.formatMessage({ id: "Site Preparation" })}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${intl.locale}/installation-process`}>
                      <span>
                        {intl.formatMessage({ id: "Installation Process" })}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${intl.locale}/color-options`}>
                      <span>{intl.formatMessage({ id: "Color Options" })}</span>
                    </Link>
                  </li>
                </ul>
                <h2>{intl.formatMessage({ id: "About Us" })}</h2>
                <ul>
                  <li>
                    <Link to={`/${intl.locale}/about-us`}>
                      <span>{intl.formatMessage({ id: "Metal" })}About Us</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${intl.locale}/faq`}>
                      <span>{intl.formatMessage({ id: "Metal" })}FAQs</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${intl.locale}/contact-us`}>
                      <span>
                        {intl.formatMessage({ id: "Metal" })}Contact Us
                      </span>
                    </Link>
                  </li>
                </ul>
                <h2>{intl.formatMessage({ id: "Articles" })}</h2>
                <ul>
                  {articles.map(article => (
                    <li key={v4()}>
                      <Link to={`/${intl.locale}${article.node.slug}`}>
                        <span>{article.node.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </SitemapItem>
              <SitemapItem>
                <h2>{intl.formatMessage({ id: "Products" })}</h2>
                <ul>
                  {products.map(product => (
                    <li key={v4()}>
                      <Link to={`/${intl.locale}${product.node.url}`}>
                        <span>{product.node.productName}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </SitemapItem>
            </SitemapGrid>
          </SectionStatic>
        </div>
      </Section>
      <Section pt="90px" pb="150px" xpt="60px" xpb="90px" bgColor="#F9F9F9">
        <div className="container">
          <SectionTitle>
            {intl.formatMessage({ id: "You still have a question?" })}
          </SectionTitle>
          <SectionDescription maxWidth="670px" mb="50px">
            {intl.formatMessage({
              id:
                "If you cannot find answer to your question in our FAQ, you can always contact us. We will answer to you shortly!",
            })}
          </SectionDescription>
          <ContactCard intl={intl} />
        </div>
      </Section>
    </Layout>
  )
}

export default injectIntl(SitemapPage)

export const pageQuery = graphql`
  query SitemapPageQuery($locale: String) {
    allContentfulProduct(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          url
          node_locale
          productName
        }
      }
    }
    allContentfulArticle(
      sort: { order: DESC, fields: postDate }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          node_locale
          title
          slug
          postDate(formatString: "D MMM YYYY")
        }
      }
    }
  }
`
